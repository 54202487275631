<template>
  <home-tpl>
    <b-card
      title=""
      style="max-width: 40rem; margin-top: 5%"
      class=" mx-auto"
      
      align-v="center">

      <b-row>
        <b-col>

          <router-link to="/newsletter/criar"> 
            <b-icon icon="envelope" font-scale="4.5"></b-icon><br>
            Enviar E-mail    
          </router-link>

        </b-col>
        <b-col>
          
          <router-link to="/newsletter/listar">
            <b-icon icon="list" font-scale="4.5"></b-icon><br>           
            Listar E-mails
          </router-link>          
        </b-col>

        <b-col>

        <b-col>

          <router-link to="#"> 
            <b-icon icon="journal-code" font-scale="4.5"></b-icon><br>
            Consultas SQL  
          </router-link>          
                   
        </b-col>

        </b-col>

      </b-row>

      <b-row class="mt-5">
        <b-col>
          <router-link to="#">
            <b-icon icon="pie-chart" font-scale="4.5"></b-icon><br>           
            Estatísticas
          </router-link>           
        </b-col>
        <b-col>
          
          <router-link to="#">
            <b-icon icon="calendar3" font-scale="4.5"></b-icon><br>           
            Agendamentos
          </router-link>          
        </b-col>

         

        <b-col>
          <a v-on:click="sair()">
            <b-icon icon="door-open" font-scale="4.5"></b-icon><br>           
            Sair
          </a>         
        </b-col>


      </b-row>             


      
      
      
      </b-card>
  </home-tpl>
</template>

<script>
// @ is an alias to /src
import HomeTpl from '@/tpls/HomeTpl.vue'

export default {
  name: 'Home',
  components: {
    HomeTpl
  },

  methods:{
    sair(){
      this.$store.commit('setUsuario',null);
      sessionStorage.clear();
      this.usuario = false;
      this.$router.push('/');
    }
  }  
}
</script>
